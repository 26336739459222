import { Injectable, inject } from '@angular/core';
import { SsApiService } from '../api/ss-api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { CommonDataService } from '../common-data.service';
import { GamesService } from '../games/games.service';
import { FiltersService } from 'ngx-unificator/services';
import { LocalstorageService } from 'ngx-unificator/services';
import { TimeService } from '../time.service';
import { TransactionLimit } from '../../../../environments/environment';
import { PaymentsMethod } from '../../vendor/ss-payments-v2/ss-payment-types';

/**
 * Available transaction statuses (for frontend only)
 */
export enum TransactionStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DISCARDED = 'discarded'
}

export enum TRANSACTIONS_TYPES {
  CASHOUT = 'cashout',
  DEPOSIT = 'deposit',
}

@Injectable({
  providedIn: 'root',
})
export class UserTransactionsService {
  private _api = inject(SsApiService);
  private _data = inject(CommonDataService);
  private _games = inject(GamesService);
  private _filters = inject(FiltersService);
  private _storage = inject(LocalstorageService);
  private _time = inject(TimeService);


  /**
   * True if limit and transaction disabled
   * @private
   */
  private _isTransactionDisabled: boolean;
  public transactionTimeValue: number = TransactionLimit.DISABLED_TIME;
  public canShowDisableTimer = false;

  public transactionList$: BehaviorSubject<any> = new BehaviorSubject<[]>([]);

  get TransactionAction() {
    return TRANSACTIONS_TYPES;
  }

  get isTransactionTemporaryDisabled() {
    return this._isTransactionDisabled;
  }

  /**
   * Returns users transactions list
   */
  transactionList(): Observable<any> {
    return this._api.playerPayments().pipe(
      map(list => list.map(transaction => ({
        ...transaction,
        amount: this._data.subunitsToUnits(transaction.amount_cents, transaction.currency),
        currency_symbol: this._data.currencySymbol(transaction.currency),
        created_at: new Date(transaction.created_at),
        status: transaction.finished_at ?
          transaction.success ?
            TransactionStatus.ACCEPTED :
            TransactionStatus.DISCARDED :
          TransactionStatus.PENDING,
      }))),
      tap(list => this.transactionList$.next(list)),
      catchError(error => of([]),
      ));
  }

  /**
   * Returns users bets list
   */
  betList(): Observable<any> {
    const games = new Set();
    const bets = [];

    return this._api.playerGames().pipe(
      tap(list => {
        list.forEach(bet => {
          games.add(bet.game);
          bets.push({
            ...bet,
            amount: this._data.subunitsToUnits(bet.total_bets, bet.currency),
            win: this._data.subunitsToUnits(bet.total_wins, bet.currency),
            currency_symbol: this._data.currencySymbol(bet.currency),
            date: new Date(bet['created_at']),
          });
        });
      }),
      switchMap(() => this._games.list({
        'external_id[]': [...games],
      })),
      filter(response => !!response),
      map(gameResponse => {
        const gamesByExternalId = this._filters.valueAsKeyObject('externalId', gameResponse.gameList || []);

        return bets.map(bet => ({
          ...bet,
          game: gamesByExternalId[bet.game],
        }));
      }),
    );
  }

  /**
   * Cancel pending cash out
   *
   * @param id
   */
  cashoutRecall(id: string): Observable<any> {
    return this._api.playerPaymentsRecall(id);
  }


  /**
   * Handles failed transactions for a given payment method.
   * @param paymentArray - Array of payment transactions.
   * @param paymentMethod - The payment method for which failed transactions are checked.
   */
  public handleFailedTransactions(paymentArray: any[], paymentMethod: PaymentsMethod) {
    let todayTransactionByBrand = this._filterTransactionsByDate(paymentArray, paymentMethod.brand);
    let transactionLimitStorage = JSON.parse(this._storage.get(TransactionLimit.STORAGE_KEY)) || [];
    const limitStorageBrand = transactionLimitStorage?.find(t => t?.paymentMethod === paymentMethod?.brand);

    if (todayTransactionByBrand?.length) {
      if (todayTransactionByBrand?.slice(0, TransactionLimit.MAX_COUNT).some(t => t?.success) && limitStorageBrand && !limitStorageBrand.isDisabled) {
        // If the limit of unsuccessful deposit is not reached, then there is a successful deposit
        transactionLimitStorage = this._removeTransactionFromLocalStorageArray(transactionLimitStorage, paymentMethod?.brand);
        this._storage.set(TransactionLimit.STORAGE_KEY, JSON.stringify(transactionLimitStorage));
        this.canShowDisableTimer = false;
        return this._isTransactionDisabled = false;
      } else if (limitStorageBrand && limitStorageBrand?.isDisabled) {
        // If there is already a limit, then block the button and do not change the timer
        this.canShowDisableTimer = true;
        return this._isTransactionDisabled = true;
      } else if (limitStorageBrand && !limitStorageBrand?.isDisabled && limitStorageBrand?.lastTransactionId === todayTransactionByBrand[0]?.id) {
        // If the timer has already passed, we show the deposit button
        this.canShowDisableTimer = false;
        return this._isTransactionDisabled = false;
      } else if (transactionLimitStorage?.length && limitStorageBrand && !limitStorageBrand?.isDisabled && limitStorageBrand?.lastTransactionId !== todayTransactionByBrand[0]?.id) {
        // We update the timer if the number of unsuccessful deposits exceeds the maximum allowed amount
        // from the last unsuccessful transaction when the timer was displayed
        const lastTransactionIndex = todayTransactionByBrand.findIndex(t => t.id === limitStorageBrand?.lastTransactionId);
        const arrayAfterLastTransaction = todayTransactionByBrand.slice(0, lastTransactionIndex);

        if (arrayAfterLastTransaction?.length >= TransactionLimit.MAX_COUNT && arrayAfterLastTransaction.slice(0, TransactionLimit.MAX_COUNT).every(t => !!t && t?.status === TransactionStatus.DISCARDED)) {
          this._updateTransactionInStorage(transactionLimitStorage, paymentMethod, todayTransactionByBrand);
          this.canShowDisableTimer = true;
          return this._isTransactionDisabled = true;
        }

        this.canShowDisableTimer = false;
        return this._isTransactionDisabled = false;
      } else {
        // If the deposit limit is reached, we block the button
        if (todayTransactionByBrand?.slice(0, TransactionLimit.MAX_COUNT).filter(t => !!t && t?.status === TransactionStatus.DISCARDED)?.length >= TransactionLimit.MAX_COUNT) {
          this._updateTransactionInStorage(transactionLimitStorage, paymentMethod, todayTransactionByBrand);
          this.canShowDisableTimer = true;
          return this._isTransactionDisabled = true;
        }

        return this._isTransactionDisabled = false;
      }
    } else {
      this.canShowDisableTimer = false;
      this._isTransactionDisabled = false; // If there are no transactions for the payment - false
    }
  }

  /**
   * Update transaction in local storage
   *
   * @param transactionLimitStorage
   * @param paymentMethod
   * @param todayTransactionByBrand
   * @private
   */
  private _updateTransactionInStorage(transactionLimitStorage: any[], paymentMethod: PaymentsMethod, todayTransactionByBrand: any[]) {
    transactionLimitStorage = this._removeTransactionFromLocalStorageArray(transactionLimitStorage, paymentMethod?.brand);
    this._addTransactionToLocalStorage(transactionLimitStorage, paymentMethod, todayTransactionByBrand[0]?.id);
    this._storage.set(TransactionLimit.STORAGE_KEY, JSON.stringify(transactionLimitStorage));
  }

  /**
   * Add transaction limit to local storage
   *
   * @param transactionLimitStorage
   * @param paymentMethod
   * @param lastTransactionId
   * @private
   */
  private _addTransactionToLocalStorage(transactionLimitStorage: any[], paymentMethod: PaymentsMethod, lastTransactionId: any) {
    transactionLimitStorage.push({
      paymentMethod: paymentMethod?.brand,
      lastTransactionId,
      enableTime: new Date(new Date().getTime() + TransactionLimit.DISABLED_TIME * 60 * 1000),
      isDisabled: true,
    });
  }

  /**
   * Remove transaction from local storage
   * @param localStorageArray
   * @param brand
   * @private
   */
  private _removeTransactionFromLocalStorageArray(localStorageArray: any[], brand: string) {
    return localStorageArray?.filter(t => t.paymentMethod !== brand);
  }

  /**
   * Filter transaction by date and brand
   *
   * @param transactions
   * @param paymentMethodBrand
   * @private
   */
  private _filterTransactionsByDate(transactions: any[], paymentMethodBrand: string) {
    return transactions
      .sort((a, b) => b.id - a.id)
      .filter(payment =>
        this._time.isTodayDate(new Date(payment.created_at)) &&
        paymentMethodBrand === payment.brand);
  }

  public checkTimer(data: { value: number, brand: string }) {
    if (data.value === 0) {
      let transactionLimitStorage = JSON.parse(this._storage.get(TransactionLimit.STORAGE_KEY)) || [];
      const localPaymentLimit = transactionLimitStorage.find(t => t.paymentMethod === data?.brand);
      if (localPaymentLimit) {
        localPaymentLimit.isDisabled = false;
        this.canShowDisableTimer = false;
        this._isTransactionDisabled = false;
        this._storage.set(TransactionLimit.STORAGE_KEY, JSON.stringify(transactionLimitStorage));
      }
    }
  }
}
