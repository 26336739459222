import { EventEmitter, inject, Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SsApiService } from '../api/ss-api.service';
import { UserService } from './user.service';
import { catchError, filter, finalize, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { combineLatest, defer, EMPTY, forkJoin, Observable, of, ReplaySubject, take } from 'rxjs';
import { CacheService } from '../cache/cache.service';
import { ContentUpdaterService } from '../content-updater/content-updater.service';
import { FormsErrorHandlerService } from '../forms-error-handler.service';
import { CommonDataService } from '../common-data.service';
import { EnvironmentService } from '../environment.service';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from '../google-tag-manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { WrSocketService } from '../wr-socket.service';
import { ValidationPatterns } from '../../helpers/validation-patterns';
import { StaticContentService } from '../static-content.service';
import { CustomValidators } from 'ngx-unificator/helpers';
import { ToastMessageService } from '../../shared/modules/toast-message/toast-message.service';
import { GlobalFieldsConfig } from '../../helpers/global-field.config';
import { UserInfoService } from './user-info.service';
import { LocalHistoryService } from '../local-history.service';
import { StagDetectorService } from '../stag-detector.service';
import { LanguageService } from '../language/language.service';

export interface AuthBanner {
  Title: string;
  Description: string;
  MainImage: string;
  Prize?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  private _static = inject(StaticContentService);
  private _fb = inject(UntypedFormBuilder);
  private _ssApi = inject(SsApiService);
  private _user = inject(UserService);
  private _cache = inject(CacheService);
  private _contentUpdater = inject(ContentUpdaterService);
  private _formErrors = inject(FormsErrorHandlerService);
  private _toastMessage = inject(ToastMessageService);
  private _commonData = inject(CommonDataService);
  private _env = inject(EnvironmentService);
  private _router = inject(Router);
  private _gtm = inject(GoogleTagManagerService);
  private _wrSocket = inject(WrSocketService);
  private _userInfo = inject(UserInfoService);
  private _local = inject(LocalHistoryService);
  private _stag: StagDetectorService = inject(StagDetectorService);
  private _lang: LanguageService = inject(LanguageService);

  public isSecondStep: boolean;
  public registered$: EventEmitter<any> = new EventEmitter<any>();
  public loggedIn$: EventEmitter<any> = new EventEmitter<any>();
  public error$: EventEmitter<any> = new EventEmitter<any>();
  public loading = false;
  public isStepLoading = false;
  public loadingEmail = false;
  public selectedCountry: string;

  private _bannerForm$: ReplaySubject<AuthBanner> = this.getBanner$('register-welcome-banner');

  private _is2FAEnabled$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  private _registerForm: FormGroup = this._fb.group({
    email: [null, [...GlobalFieldsConfig.email.validator]],
    password: [null, [...GlobalFieldsConfig.password.validator]],
    country: [null],
    currency: [null],
    receive_promos: [true],
    receive_sms_promos: [false],
    terms_acceptance: [true, [CustomValidators.changeKey('required_true', Validators.requiredTrue)]],
    age_acceptance: [true, [CustomValidators.changeKey('required_true', Validators.requiredTrue)]],
    bonus_code: [null, Validators.pattern(ValidationPatterns.lettersAndNumbers)],
    otp_attempt: [null], // for login
  });

  private _loginForm: FormGroup = this._fb.group({
    email: [null, [...GlobalFieldsConfig.email.validator]],
    password: [null, [...GlobalFieldsConfig.password.validator]],
    otp_attempt: [null],
  });

  private _enhancedRegisterForm: FormGroup = this._fb.group({
    email: [null, [...GlobalFieldsConfig.email.validator]],
    password: [null, [...GlobalFieldsConfig.password.validator]],
    country: [null, [Validators.required]],
    currency: [null, [Validators.required]],
    terms_acceptance: [true, [CustomValidators.changeKey('required_true', Validators.requiredTrue)]],
    age_acceptance: [false, [CustomValidators.changeKey('required_true', Validators.requiredTrue)]],
    receive_promos: [false],
    bonus_code: [null],
  });


  private _missedFieldsForm: FormGroup;

  public enhancedReg$: Observable<any> = this._static.item({ slug: 'full-register-stags' }).pipe(
    filter(data => data?.length),
    tap(() => {
      this.setDefaultCurrency(this.enhancedRegisterForm);
      this.setCountry(this.enhancedRegisterForm);
    }),
    take(1),
  );

  get registerForm(): FormGroup {
    return this._registerForm;
  }

  get loginForm(): FormGroup {
    return this._loginForm;
  }

  get enhancedRegisterForm(): FormGroup {
    return this._enhancedRegisterForm;
  }

  get bannerForm$(): ReplaySubject<AuthBanner> {
    return this._bannerForm$;
  }

  get is2FAEnabled$(): Observable<boolean> {
    return this._is2FAEnabled$.asObservable();
  }

  submitRegistration() {
    this._onSubmitReg$(this.registerForm).subscribe();
  }

  onEnhancedRegSubmit(missedFieldsForm: FormGroup) {
    missedFieldsForm.removeControl('receive_promos');
    missedFieldsForm.removeControl('receive_sms_promos');

    if (missedFieldsForm.get('mobile_phone').value && missedFieldsForm.get('mobile_phone')?.value?.includes('null')) {
      missedFieldsForm.get('mobile_phone').setErrors({ required: true });
    }

    if (missedFieldsForm.invalid) {
      this._formErrors.applyFormErrors(missedFieldsForm, null, true);
      return;
    }

    this.loading = true;

    const formData = {
      ...missedFieldsForm.getRawValue(),
    };

    this._missedFieldsForm = missedFieldsForm;

    this._onSubmitReg$(this.enhancedRegisterForm, formData).pipe(
      switchMap(() => combineLatest([this._user.getUserInfo(), this._user.getPlayerStats()])),
      finalize(() => this.loading = false),
    ).subscribe();
  }

  private _onSubmitReg$(form: FormGroup, enhancedFormValues?: any): Observable<any> {
    this._setAdditionalFields(form);

    if (form.invalid) {
      const errors = {};
      this._formErrors.applyFormErrors(form, null, true);
      Object.keys(form.controls).forEach(control => {
        if (form.controls[control]?.errors) {
          errors[control] = form.controls[control]?.errors;
        }
      });
      this._gtm.registerError('register_error', errors);
      return EMPTY;
    }

    this.loading = true;
    const formData = {
      ...form.getRawValue(),
      bonus_code: form.get('bonus_code')?.value?.toUpperCase(),
      country: form.get('country')?.value?.toUpperCase(),
    };

    return defer(() => {
      if (this._user.auth) {
        return this._updateUserInfo(enhancedFormValues);
      }

      return this._ssApi.usersSignUp({ user: formData }).pipe(
        catchError(error => {
          if (((error.error.errors || {}).email || {}).taken && !enhancedFormValues) {
            this._router.navigate(
              ['/', this._lang.current, 'login'],
              {
                state: {
                  data: {
                    email: formData.email,
                    password: formData.password,
                  },
                },
              }).then();
          }
          return EMPTY;
        }),
        tap(response => this._handleSignupSuccess(response)),
        switchMap(() => this._updateUserInfo(enhancedFormValues)),
      );
    }).pipe(
      tap(() => this._processSignUpRedirects()),
      catchError(error => {
        this._handleSignupError(error);
        return EMPTY;
      }),
      finalize(() => (this.loading = false)),
    );
  }

  submitLogin() {
    if (this._loginForm.invalid) {
      this._formErrors.applyFormErrors(this._loginForm, null, true);
      return;
    }

    this.loading = true;

    this._ssApi
      .usersSignIn({ user: this._loginForm.getRawValue() })
      .pipe(
        tap(response => this._handleLoginSuccess(response)),
        switchMap(() => this._stag.resolveStagParam$()),
        catchError(error => of(this._handleLoginError(error))),
        finalize(() => (this.loading = false)),
      )
      .subscribe();
  }

  public getBanner$(slug: string) {
    return this._static
      .item({
        slug,
      })
      .pipe(
        filter(res => !!res),
        map(res => (res?.length ? res[0] : null)),
        shareReplay(1),
      ) as ReplaySubject<any>;
  }

  private _updateUserInfo(enhancedFormValues): Observable<any> {
    if (!enhancedFormValues) {
      return of(null);
    }

    const playerInfo = enhancedFormValues
      ? { ...enhancedFormValues }
      : { country: this._env.env?.country?.short?.toUpperCase() };

    return this._userInfo.updatePlayerInfo(playerInfo, 'edition').pipe(
      catchError(error => {
        this._formErrors.applyFormErrors(this._missedFieldsForm, error?.error, true);
        return EMPTY;
      }),
    );
  }

  public defaultActions(form: FormGroup) {
    this.setDefaultCurrency(form);
    this.setCountry(form);
    // this.generateNick(form);
    this._isAgeAcceptanceFieldCheckedForGeo(form);
  }

  /**
   * Check if age acceptance field should be checked
   *
   * @private
   */
  private _isAgeAcceptanceFieldCheckedForGeo(form: FormGroup) {
    this._static
      .item({
        slug: 'active-age-acceptance-checkbox-by-geo',
      })
      .pipe(
        filter(item => item?.length),
        tap(() => {
          form.get('age_acceptance')?.setValue(true);
          form.get('terms_acceptance')?.setValue(true);
        }),
        take(1),
      )
      .subscribe();
  }

  public setDefaultCurrency(form?: FormGroup) {
    this._setCurrencyByDefault(form);
  }

  /**
   * Set currency by default according payment mode
   * @private
   */
  private _setCurrencyByDefault(form: FormGroup) {
    form.get('currency').setValue(this._env.env.currency.short || 'USD');
  }

  // public generateNick(form: FormGroup) {
  //   form.get('nickname').setValue(this._generateNickname());
  // }

  /**
   * Set country to register form
   */
  public setCountry(form: FormGroup) {
    this._commonData.loaded$
      .pipe(
        first(),
        tap(() => {
          if (this._commonData.countryList.some(country => country.short === this._env.env.country.short)) {
            form.get('country')?.setValue(this._env.env?.country?.short?.toLowerCase());
            // form.get('country')?.disable();
          }
        }),
      )
      .subscribe();
  }

  public onClickStep() {
    const controlsToCheck = ['email', 'password', 'country'];
    let isFormValid = true;

    controlsToCheck.forEach(controlName => {
      const control = this.enhancedRegisterForm.get(controlName);
      if (control && control.invalid) {
        control.markAsTouched();
        isFormValid = false;
      }
    });
    if (!isFormValid) {
      return;
    }

    if (isFormValid) {
      this.isStepLoading = true;

      this._ssApi.usersSignUp({
        user: this.enhancedRegisterForm.value,
      }).pipe(
        catchError(error => {
          if (((error.error.errors || {}).email || {}).taken) {
            this.isStepLoading = false;
            this._router.navigate(
              ['/', this._lang.current, 'login'],
              {
                state: {
                  data: {
                    email: this.enhancedRegisterForm.get('email').value,
                    password: this.enhancedRegisterForm.get('password').value,
                  },
                },
              });
          } else {
            this.isStepLoading = false;
            this.isSecondStep = true;
          }
          return EMPTY;
        }),
        tap(() => {
          if (this.enhancedRegisterForm.get('country')?.value) {
            this.selectedCountry = this.enhancedRegisterForm.get('country')?.value;
          }
          this.isStepLoading = false;
          this.isSecondStep = true;
        }),
      ).subscribe();
    }
  }

  private _handleSignupSuccess(response: any) {
    this._cache.clear();
    this._user.applyUserInfo(response);
    // this._admil.onRegister(response);

    forkJoin([this._user.getUserAccounts(), this._user.getUserAccountsCompatibility()]).subscribe();

    this._contentUpdater.updateAll();
    this._gtm.signUpSuccess(response.id);
    this._gtm.signUpSuccessTimestamp('reg_succ_time', 'reg_succ', response.id);
    this.registered$.next(response);
  }

  private _handleSignupError(error: HttpErrorResponse) {
    this._formErrors.applyFormErrors(
      this._registerForm,
      {
        errors: {
          ...(error.error.errors || {}),
          ...((error.error.errors && error.error.errors.profile) || {}),
        },
      },
      true,
    );

    this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error));
    this._gtm.registerError('register_error', error.error.errors);
    this.error$.next(error);
    this.isSecondStep = false;
    return EMPTY;
  }

  private _handleLoginSuccess(response: any) {
    this._cache.clear();
    this._user.applyUserInfo(response);
    this._contentUpdater.updateAll();
    forkJoin([this._user.getUserAccounts(), this._user.getUserAccountsCompatibility()]).subscribe();
    this.loggedIn$.next(response);
    this._wrSocket.sendEventLogin();
    this._processLoginRedirects();
    this._gtm.signInSuccess(response.id);
  }

  private _handleLoginError(error: HttpErrorResponse) {
    if (error.status === 422 && error.error.required && error.error.required.includes('one_time_password')) {
      this._is2FAEnabled$.next(true);
      return of(error);
    } else {
      this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error)[0] || 't.invalid-email-pass');
      this._formErrors.applyFormErrors(this._loginForm, error.error, true);
      return of(error);
    }
  }

  private _processLoginRedirects() {
    const beforeLoginUrl = this._local.getUrl('promo-auth');

    let urlToRedirect: any[] = beforeLoginUrl ? [beforeLoginUrl.url] : ['/'];
    this._router.navigate(urlToRedirect);
  }

  private _processSignUpRedirects() {
    if (Array.from(this._local.routes.keys()).some(key => key.includes('promo'))) {
      this._router.navigateByUrl(this._local.getUrl('promo-auth').url);
    } else {
      this._router.navigate(['/deposit']).then(() => {
        this._toastMessage.success('t.register-success');
      });
    }
  }

  /**
   * Set values to fields that not exists in form
   *
   * @private
   */
  private _setAdditionalFields(form: FormGroup) {
    form.get('age_acceptance')?.setValue(form.get('terms_acceptance')?.value);
    form.get('receive_promos')?.setValue(form.get('receive_promos')?.value);
  }

  // private _generateNickname(): string {
  //   const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   return Array.from({ length: 12 }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
  // }
}
