import { environment } from 'src/environments/environment';
import { Arguments, RedirectRoutes } from './ss-payment-types';

export const SS_LIB_CONFIG: Arguments.ConfigArgs = {
  serverUrl: environment.ss_host,
  cdnList: ['https://contentdeliverynetwork.cc'],
};

export const redirectRoutes = (action: any): RedirectRoutes => {
  return {
    successUrl: (location?.origin as string) + `/payment-status/${action}/success`,
    failureUrl: (location?.origin as string) + `/payment-status/${action}/failure`,
    pendingUrl: (location?.origin as string) + '/profile/history',
    supportUrl: (location?.origin as string) + '/faq',
  };
};

export const CUSTOM_ROUTES: RedirectRoutes = {};

/**
 * Methods which have own image on our side
 */
export const methodsWithOwnImage = ['googlepay', 'noda~noda', 'applepay', 'ari-10~ideal'];
export const methodsWithOwnImageByIds = [
  'ADA_coinspaid',
  'BCH_coinspaid',
  'BTC_coinspaid',
  'DOG_coinspaid',
  'ETH_coinspaid',
  'LTC_coinspaid',
  'TRX_coinspaid',
  'USDT_coinspaid',
  'mandato~mandato-direct',
  'applepay_visa',
  'applepay_mc',
  'creditcard-4_mastercard',
];
