import { computed, inject, Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { switchMap, tap } from 'rxjs/operators';
import { SsPaymentsV2Service } from '../../vendor/ss-payments-v2/ss-payment.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EnvironmentService } from '../environment.service';
import { CORRECTED_PHONE_NUMBERS } from 'ngx-unificator/helpers';
import { NgModel } from '@angular/forms';

export enum PhoneStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified'
}

export interface PhoneState {
  isHasVerified: boolean;
  phonePrefix: string;
  phoneMask: string;
  dynamicPhoneMask: string;
  dynamicShowMaskTyped: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserPhoneService {

  public state = computed(() => this._state());

  private _state = signal<PhoneState>({
    isHasVerified: false,
    phonePrefix: '',
    phoneMask: '',
    dynamicPhoneMask: '',
    dynamicShowMaskTyped: true,
  });

  private _api = inject(SsApiService);
  private _env = inject(EnvironmentService);
  private _ssPayments = inject(SsPaymentsV2Service);

  constructor() {
  }

  /**
   * Returns list of player phone numbers
   */
  public getPhoneList(): Observable<any> {
    return this._api.playerPhone().pipe(
      tap(list => {
        this._state.update(store => {
          return {
            ...store,
            isHasVerified: list.some(phone =>
              phone.verified_status === PhoneStatus.VERIFIED),
          };
        });
      }),
    );
  }

  /**
   * Add user phone
   *
   * @param data
   */
  public addPhone(data: object): Observable<any> {
    return this._api.postPlayerPhone({
      phone: data,
    }).pipe(
      switchMap(() => this._ssPayments.resetCache()),
    );
  }

  /**
   * Delete user phone
   */
  public deletePhone(): Observable<any> {
    return this._api.deletePlayerPhone().pipe(
      switchMap(() => this._ssPayments.resetCache()),
    );
  }

  /**
   * Verify player phone
   *
   * @param code
   */
  public verifyPhone(code: string): Observable<any> {
    return this._api.playerPhoneConfirm({
      phone: {
        code,
      },
    });
  }

  public updatePrefixStore(callingCode: string) {
    this._state.update(store => {
      return {
        ...store,
        phonePrefix: `+${callingCode} `,
      };
    });
  }

  public updateMaskStore(country: string) {
    this._state.update(store => {
      return {
        ...store,
        phoneMask: this._getPhoneExampleMask(country),
      };
    });
  }

  public updateShowMaskTypedStore(value: boolean) {
    this._state.update(store => {
      return {
        ...store,
        dynamicShowMaskTyped: value,
      };
    });
  }

  public updateDynamicMaskStore(value: string) {
    this._state.update(store => {
      return {
        ...store,
        dynamicPhoneMask: value,
      };
    });
  }

  private _getPhoneExampleMask(country: any): string {
    const exampleNumber = CORRECTED_PHONE_NUMBERS[country?.toUpperCase()];
    const strippedNumber = exampleNumber
      .replace(`${this.state().phonePrefix}`, '').replace('+', '').trim();
    return `${strippedNumber.replace(/\d/g, '0')}`;
  }

  public updatePhoneStoreMask() {
    this._env.env$.pipe(
      takeUntilDestroyed(),
      tap(({ data }) => {
        this.updatePrefixStore(data?.country?.callingCode);
        this.updateMaskStore(data?.country?.short);
        this.updateDynamicMaskStore(this.state().phoneMask);
      }),
    ).subscribe();
  }

  public extendMask() {
    this.updateDynamicMaskStore(this.state().phoneMask + '*');
    this.updateShowMaskTypedStore(false);
  }

  public resetExtendedMask() {
    this.updateDynamicMaskStore(this.state().phoneMask);
    this.updateShowMaskTypedStore(true);
  }

  public updateMask(value) {
    if (value?.length >= this.state().phoneMask.replace(/ /g, '').length) {
      this.extendMask();
    } else {
      this.resetExtendedMask();
    }
  }

  public handlePaste(event: ClipboardEvent, inputModel: NgModel) {
    event.preventDefault();
    let pastedText: any = event.clipboardData?.getData('text') || '';

    if (pastedText.length >= this.state().phoneMask.replace(/ /g, '').length) {
      this.extendMask();
    }

    inputModel.control.setValue(pastedText, { emitEvent: true });
  }


  /**
   * Request verification code
   */
  resendVerificationCode(): Observable<any> {
    return this._api.playerPhoneResendVerificationCode();
  }
}
