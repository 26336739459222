import { computed, inject, Injectable, signal } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { CookieService, LocalstorageService, WindowService } from 'ngx-unificator/services';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { CmsApiService } from './api/cms-api.service';
import { SsApiService } from './api/ss-api.service';
import { finalize, map, tap } from 'rxjs/operators';
import { NATIVE_APP_COOKIE } from './install-app.service';
import { Location } from '@angular/common';


export const PTAG_KEY = 'ptag';

export const COOKIE_ID_ID = 'id_id';
export const HEADER_ID_ID = 'id-id';


export const STAG_KEY = 'stag';

@Injectable({
  providedIn: 'root',
})
export class StagDetectorService {
  private _cookie: CookieService = inject(CookieService);
  private _router: Router = inject(Router);
  private _serializer: UrlSerializer = inject(UrlSerializer);
  private _localStorage: LocalstorageService = inject(LocalstorageService);
  private _ssApi: SsApiService = inject(SsApiService);
  private _window: WindowService = inject(WindowService);
  private _cmsApi: CmsApiService = inject(CmsApiService);
  private _location: Location = inject(Location);

  private _state = signal({
    params: this._serializer.parse(this._window.nativeWindow.location.search).queryParams,
    domain: this._window.nativeWindow.location.hostname
      .split('.').slice(-2).join('.'),
  });

  public params = computed(() => this._state().params);
  public domain = computed(() => this._state().domain);

  /**
   * Detect params from url
   */
  public detectParams() {
    this.resolveStagParam$().pipe(
      switchMap(() => {
        const params = this.params();
        const domain = location.hostname.split('.').slice(-2).join('.');

        if (params?.test) {
          this._resolveTestParam();
        }

        if (params.app || params.appver || params.appID) {
          this._resolveAppParam();
        }

        if (params?.nocache) {
          this._resolveNocacheParam();
        }

        if (params.affb_id) {
          this._resolveAffbIdParam();
        }

        return EMPTY;
      }),
    ).subscribe();
  }

  /**
   * Resolve stag param
   */
  public resolveStagParam$(): Observable<any> {
    const stagValueFromParams = this.params()?.stag?.split('_')[0];

    const stagFromApi$ = this._ssApi.infoAffiliate().pipe(
      map(({ stag }) => stag?.split('_')[0]),
    );

    const stagFromParams$ = stagValueFromParams
      ? this._ssApi.infoLocales({ stag: this.params().stag }).pipe(
        map(() => stagValueFromParams),
      )
      : null;

    const stagUpdate$ = stagFromParams$
      ? stagFromParams$.pipe(
        tap((stagValueFromParams) => {
          this._setStagData(stagValueFromParams);
        }),
      )
      : stagFromApi$.pipe(
        tap((stagValueFromApi) => {
          this._setStagData(stagValueFromApi);
        }),
      );

    return stagUpdate$.pipe(
      finalize(() => {
        if (stagValueFromParams)
          this._updateUrlWithoutParameters();
      }),
    );
  }

  /**
   * Resolve test param
   * @private
   */
  private _resolveTestParam() {
    this._localStorage.set('test', 'true');
    this._cookie.set('test', 'true', 999, '/', this.domain());
    this._updateUrlWithoutParameters();
  }

  private _resolveAppParam() {
    this._cookie.set(NATIVE_APP_COOKIE, 'true', 999, '/', this.domain());
    this._localStorage.set(NATIVE_APP_COOKIE, 'true');
  }

  /**
   * Resolve nocache param
   * @private
   */
  private _resolveNocacheParam() {
    this._updateUrlWithoutParameters();
  }

  private _resolveAffbIdParam() {
    this._cookie.set('affb_id', `${this.params().affb_id}`, 999, '/', this.domain());
    this._localStorage.set('affb_id', `${this.params().affb_id}`);
  }

  /**
   * Update url tree
   * @private
   */
  private async _updateUrlWithoutParameters() {
    const tree = this._router.parseUrl(this._location.normalize(window.location.pathname));
    const queryParams = tree.queryParams;
    delete queryParams.param;

    tree.queryParams = queryParams;

    await this._router.navigateByUrl(tree, { replaceUrl: true });

    this._state.update(state => ({
      ...state,
      params: {},
    }));
  }


  /**
   * Storage cookie and local stag data
   * @param value
   * @param domain
   * @private
   */
  private _setStagData(value: string) {
    if (value) {
      this._cookie.set(COOKIE_ID_ID, value, 99, '/', this.domain());
      this._cookie.set(HEADER_ID_ID, value, 99, '/', this.domain());
      this._cookie.set(PTAG_KEY, value, 99, '/', this.domain());
      this._localStorage.set(STAG_KEY, value);
      this._localStorage.set(COOKIE_ID_ID, value);
    } else {
      this._cookie.delete(COOKIE_ID_ID, '/', this.domain());
      this._cookie.delete(HEADER_ID_ID, '/', this.domain());
      this._cookie.delete(PTAG_KEY, '/', this.domain());
      this._localStorage.clearItem(STAG_KEY);
      this._localStorage.clearItem(COOKIE_ID_ID);
    }

    this._cmsApi.customHeadersList.push({ key: 'ID-ID', val: this._cookie.get(HEADER_ID_ID) });
    this._cmsApi.customNoLangHeadersList.push({ key: 'ID-ID', val: this._cookie.get(HEADER_ID_ID) });
  }
}
